























import { Component, Prop, Vue } from "vue-property-decorator";
import can from '@/shared/helpers/can.helper'
import __ from '@/shared/helpers/__'
import FormBase from "@/shared/classes/form/form-base";
import Field, { FieldSizes } from "@/shared/classes/form/field";
import { FieldTypes } from "@/shared/components/form/field-types";
import Form from "@/shared/components/form/Form.vue";

@Component({
  components: { Form },
  methods: { can, __ }
})
export default class HandbookFilters extends Vue {
  @Prop() onFilterChange!: (data: any) => void

  contentsForm: FormBase | null = null
  risksForm: FormBase | null = null

  filters: any = {}

  created() {
    this.contentsForm = new FormBase()
      .setFields([
        new Field()
          .setType(FieldTypes.checkbox)
          .setKey('isHseDeclarationVisible')
          .setTitle(__("company.components.hse-handbook.handbook-filters.form.hse-declaration"))
          .setDense(true)
          .setSize(FieldSizes.fourTwelfth)
          .setOnChange(() => this.contentsFormRef?.submit()),
        new Field()
          .setType(FieldTypes.checkbox)
          .setKey('isCompanyInformationVisible')
          .setTitle(__("company.components.hse-handbook.handbook-filters.form.company-info"))
          .setDense(true)
          .setSize(FieldSizes.fourTwelfth)
          .setOnChange(() => this.contentsFormRef?.submit()),
        new Field()
          .setType(FieldTypes.checkbox)
          .setKey('isSafetyVisible')
          .setTitle(__("company.components.hse-handbook.handbook-filters.form.safety-decision"))
          .setDense(true)
          .setSize(FieldSizes.fourTwelfth)
          .setOnChange(() => this.contentsFormRef?.submit()),
        new Field()
          .setType(FieldTypes.checkbox)
          .setKey('isOrgStructureVisible')
          .setTitle(__("company.components.hse-handbook.handbook-filters.form.org-structure"))
          .setDense(true)
          .setSize(FieldSizes.fourTwelfth)
          .setOnChange(() => this.contentsFormRef?.submit()),
        new Field()
          .setType(FieldTypes.checkbox)
          .setKey('isRiskAssessmentVisible')
          .setTitle(__("company.components.hse-handbook.handbook-filters.form.risk-assessment"))
          .setDense(true)
          .setSize(FieldSizes.fourTwelfth)
          .setOnChange(() => this.contentsFormRef?.submit()),
        new Field()
          .setType(FieldTypes.checkbox)
          .setKey('isHseRoutinesVisible')
          .setTitle(__("company.components.hse-handbook.handbook-filters.form.hse-routines"))
          .setDense(true)
          .setSize(FieldSizes.fourTwelfth)
          .setOnChange(() => this.contentsFormRef?.submit()),
      ])
      .setInitialValues({
        isHseDeclarationVisible: true,
        isCompanyInformationVisible: true,
        isSafetyVisible: true,
        isOrgStructureVisible: true,
        isRiskAssessmentVisible: true,
        isHseRoutinesVisible: true
      })
      .setSubmit(false)
      .setOnSuccess(data => {
        this.filters = {
          ...this.filters,
          ...data
        }
        this.onFilterChange(this.filters);
      })

    this.risksForm = new FormBase()
      .setFields([
        new Field()
          .setType(FieldTypes.datePicker)
          .setKey('dateFrom')
          .setTitle(__("company.components.hse-handbook.handbook-filters.form.date-from"))
          .setDense(true)
          .setSize(FieldSizes.half)
          .setOnChange(() => this.risksFormRef?.submit()),
        new Field()
          .setType(FieldTypes.datePicker)
          .setKey('dateTo')
          .setTitle(__("company.components.hse-handbook.handbook-filters.form.date-to"))
          .setDense(true)
          .setSize(FieldSizes.half)
          .setOnChange(() => this.risksFormRef?.submit()),
        new Field()
          .setType(FieldTypes.label)
          .setTitle(`${__("company.components.hse-handbook.handbook-filters.form.risk-status")}:`)
          .setDense(true)
          .setSize(FieldSizes.fourTwelfth)
          .setClasses('caption mt-2'),
        new Field()
          .setType(FieldTypes.checkbox)
          .setKey('isInProgress')
          .setTitle(__("company.components.hse-handbook.handbook-filters.form.in-progress"))
          .setDense(true)
          .setHideDetails(true)
          .setSize(FieldSizes.fourTwelfth)
          .setOnChange(() => this.risksFormRef?.submit()),
        new Field()
          .setType(FieldTypes.checkbox)
          .setKey('isCompleted')
          .setTitle(__("company.components.hse-handbook.handbook-filters.form.completed"))
          .setDense(true)
          .setHideDetails(true)
          .setSize(FieldSizes.fourTwelfth)
          .setOnChange(() => this.risksFormRef?.submit()),
      ])
      .setInitialValues({
        isInProgress: true,
        isCompleted: true
      })
      .setSubmit(false)
      .setOnSuccess(data => {
        this.filters = {
          ...this.filters,
          ...data
        }
        this.onFilterChange(this.filters);
      })
  }

  get contentsFormRef(): any {
    return this.$refs.contentsForm
  }

  get risksFormRef(): any {
    return this.$refs.risksForm
  }
}
