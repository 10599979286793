














import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import __ from '@/shared/helpers/__'
import HseHandbook from "@/shared/modules/hse-handbook/hse-handbook.model";
import moment from "moment";

@Component({
  methods: {
    __
  }
})
export default class HseHandbookSigningVersionHeader extends Vue {
  @Prop() item!: any
  @Inject('lastHandbook') getLastHandbook!: () => HseHandbook

  get sent() {
    if (this.item.lastSentInfo) {
      const handbook = this.item.lastSentInfo.handbook;
      return `${`0${handbook?.version}`.slice(-2)}/ ${moment(handbook?.createdAt).format('DD-MM-YYYY')}`;
    }
  }

  get signed() {
    if (this.item.lastSignedInfo) {
      const handbook = this.item.lastSignedInfo.handbook;
      return `${`0${handbook?.version}`.slice(-2)}/ ${moment(handbook?.createdAt).format('DD-MM-YYYY')}`;
    }
  }
}
