








































































import { Component, Provide, Vue } from "vue-property-decorator";
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import Company from '@/shared/modules/company/company.model'
import User from '@/shared/modules/user/user.model';
import { AuthGetters } from '@/shared/store/auth/auth.getters';
import SearchableField from "@/shared/classes/form/fields/Select/searchable-field";
import FormField from "@/shared/components/form/FormField.vue";
import Field from "@/shared/classes/form/field";
import { FieldTypes } from "@/shared/components/form/field-types";
import baseHttp from "@/shared/http";
import { getNodeApiUrlByVersion } from "@/config";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";
import Form from "@/shared/components/form/Form.vue";
import UsersFilters from "@/company/components/hse-handbook/UsersFilters.vue";
import HandbookFilters from "@/company/components/hse-handbook/HandbookFilters.vue";
import moment from "moment";
import HseHandbook from "@/shared/modules/hse-handbook/hse-handbook.model";
import HseHandbookSigningStatusHeader from "@/company/components/hse-handbook/HseHandbookSigningStatusHeader.vue";
import HseHandbookSigningVersionHeader from "@/company/components/hse-handbook/HseHandbookSigningVersionHeader.vue";
import DataTableAction from "@/shared/classes/components/data-table/data-table-action";
import Model from "@/shared/classes/model";
import IModelResponse from "@/shared/interfaces/modules/model-response.interface";
import InfoDialog from "@/shared/components/dialogs/InfoDialog.vue";
import HseRoutineTemplate from "@/shared/modules/hse-routine-templates/hse-routine-templates.model";


@Component({
  components: { DataTable, PageHeader, FormField, Form, HandbookFilters, UsersFilters },
  methods: { __ }
})
export default class HseHandbookIndex extends Vue {
  http = baseHttp

  userSelect: SearchableField | null = null
  userSelectValue = ''
  safetyField: Field | null = null
  safetyFieldValue = 'eject'
  versionSelect: SelectField | null = null
  versionSelectValue = ''
  isVisibleNewVersionApproval = false
  newVersionChangesBy = []
  handbookFilters: Record<string, any> = {}
  lastHandbook: HseHandbook | null = null

  @Provide('lastHandbook') getLastHandbook() {
    return this.lastHandbook
  }

  table = new DataTableBase()
    .setModel(User)
    .setMultipleCheckbox(true)
    .setEndpoint(`${getNodeApiUrlByVersion()}/hse-handbook-signatures/by-user`)
    .setHasFilter(false)
    .setHeaders([
      new DataTableHeader()
        .setEntryKey('fullName')
        .setText(__('company.views.hse-handbook.index.table.columns.full-name')),
      new DataTableHeader()
        .setEntryKey('position.name')
        .setText(__('company.views.hse-handbook.index.table.columns.position'))
        .setSortable(false),
      new DataTableHeader()
        .setKey('role.name')
        .setText(__('company.views.hse-handbook.index.table.columns.role'))
        .setSortable(false),
      new DataTableHeader()
        .setKey('lastSignedInfo')
        .setText(__('company.views.hse-handbook.index.table.columns.signing-date'))
        .setValueParser(value => {
          if (!value) return null
          return moment(value?.updatedAt).format('DD-MM-YYYY HH:mm:ss')
        }),
      new DataTableHeader()
        .setKey('key')
        .setText(__('company.views.hse-handbook.index.table.columns.version'))
        .setValueComponent(HseHandbookSigningVersionHeader)
        .setSortable(false),
      new DataTableHeader()
        .setKey('hseHandbookSignatures')
        .setText(__('company.views.hse-handbook.index.table.columns.signing-status'))
        .setValueComponent(HseHandbookSigningStatusHeader)
        .setSortable(false),
    ])
    .setActions([
      new DataTableAction()
        .setIcon('mdi-eye')
        .setAllowedForItem((item: any) => !!item.lastSignedInfo)
        .setAction((item: any) => {
          if (!item.lastSignedInfo) return;
          this.http.post(
            `${getNodeApiUrlByVersion()}/hse-handbook/print-with-signatures`,
            { hseHandbookUuid: item.lastSignedInfo.handbookUuid },
            { responseType: 'blob' }
          )
            .then(resp => {
              const url = window.URL.createObjectURL(resp.data);
              window.open(url, '_blank');
            });
        })
  ])

  created() {
    this.userSelect = new SearchableField()
      .setEntryKey('responsibleUserUuid')
      .setTitle(__('company.views.hse-handbook.index.new-version.safety-representative'))
      .loadItems({
        endpoint: `company/${ this.company.slug }/employees`,
        value: 'uuid',
        title: 'full_name',
        perPage: 20,
      })
      .setHideDetails(true)
      .setDense(true)
      .setValue(this.userSelectValue)
      .setOnChange((value) => this.userSelectValue = value)

    this.safetyField = new Field()
      .setType(FieldTypes.radio)
      .setKey('safety')
      .setDense(true)
      .setHideDetails(true)
      .setMeta({
        items: [
          { value: 'eject', label: __('company.views.hse-handbook.index.new-version.eject-safety') },
          { value: 'agreement-not-to-have', label: __('company.views.hse-handbook.index.new-version.agreement-safety') },
        ]
      })
      .setValue(this.safetyFieldValue)
      .setOnChange((value) => {
        this.safetyFieldValue = value;
        if (value === 'eject') this.userSelect?.setTitle(__('company.views.hse-handbook.index.new-version.safety-representative'))
        else this.userSelect?.setTitle(__('company.views.hse-handbook.index.new-version.employee-representative'))
      })


    this.versionSelect = new SelectField()
      .setKey('hseHandbookUuid')
      .setTitle(__('company.views.hse-handbook.index.signing.version'))
      .setDense(true)
      .setOnChange((value) => this.versionSelectValue = value)
    this.loadHandbookVersionSelectItems();

    this.checkIfNewVersionAvailable()
    this.getLastHandbookInfo();
  }

  getLastHandbookInfo() {
    this.http.get(`${getNodeApiUrlByVersion()}/hse-handbook?per_page=1`)
      .then(resp => {
        if (!resp.data.data.length) return;
        const handbook = resp.data.data[0];
        this.lastHandbook = handbook;

        this.versionSelectValue = handbook.uuid || '';
        this.versionSelect?.setValue(handbook.uuid || '');
        this.loadHandbookVersionSelectItems();

        this.userSelectValue = handbook.safetyRepresentativeUuid || '';
        this.userSelect?.setValue(handbook.safetyRepresentativeUuid || '');

        this.safetyFieldValue = handbook.safetyType || 'eject';
        this.safetyField?.setValue(handbook.safetyType || 'eject');
        if (handbook.safetyType === 'eject') this.userSelect?.setTitle(__('company.views.hse-handbook.index.new-version.safety-representative'))
        else this.userSelect?.setTitle(__('company.views.hse-handbook.index.new-version.employee-representative'))
      })
  }

  loadHandbookVersionSelectItems() {
    this.versionSelect?.loadItems({
      endpoint: `${getNodeApiUrlByVersion()}/hse-handbook`,
      value: 'uuid',
      title: 'version',
      titleFunction: (item: any, index: number) => {
        return `${`0${item.version}`.slice(-2)}: ${moment(item.createdAt).format('DD-MM-YYYY')}
         (${index === 0 ? __('company.views.hse-handbook.index.last') : __('company.views.hse-handbook.index.older')})`
      },
    })
  }

  checkIfNewVersionAvailable() {
    this.http.get(`${getNodeApiUrlByVersion()}/hse-handbook/has-new-version`)
      .then(resp => {
        if (resp.data.isAvailable) {
          this.isVisibleNewVersionApproval = true;
          this.newVersionChangesBy = resp.data.changesMadeBy;
        }
        else {
          this.isVisibleNewVersionApproval = false
          this.newVersionChangesBy = []
        }
      })
  }

  onHandbookFilterChange(filters: any) {
    this.handbookFilters = filters;
  }

  approveNewVersion() {
    if (!this.userSelectValue) {
      return this.$store.dispatch(GlobalActions.showSnackBar, {
        type: SnackBarTypes.error,
        message: __('company.views.hse-handbook.index.actions.representative-required')
      })
    }

    const sendForApprove = (sendToSign: boolean) => {
      this.http.post(
        `${getNodeApiUrlByVersion()}/hse-handbook/approve`,
        {
          safety: this.safetyFieldValue,
          representativeUserUuid: this.userSelectValue,
          sendToSign: sendToSign,
        }
      )
        .then(() => {
          this.$store.dispatch(GlobalActions.showSnackBar, {
            type: SnackBarTypes.success,
            message:  __('company.views.hse-handbook.index.actions.version-approved'),
          });
          this.tableRef.refresh()
          this.getLastHandbookInfo();
          this.checkIfNewVersionAvailable();
        });
    }

    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('company.views.hse-handbook.index.actions.confirm-approve'),
        yes: __('general.approve'),
        no: __('general.cancel'),
        onYes: () => {
          setTimeout(() => {
            this.$store.dispatch(GlobalActions.showDialog, {
              show: true,
              component: AreYouSureDialog,
              meta: {
                title: __('company.views.hse-handbook.index.actions.confirm-send'),
                yes: __('general.send'),
                no: __('general.cancel'),
                onYes: () => sendForApprove(true),
                onNo: () => sendForApprove(false),
              },
            })
          }, 300)
        },
      },
    })
  }

  printNewVersion() {
    if (!this.userSelectValue) {
      return this.$store.dispatch(GlobalActions.showSnackBar, {
        type: SnackBarTypes.error,
        message: __('company.views.hse-handbook.index.actions.representative-required')
      })
    }

    this.http.post(
      `${getNodeApiUrlByVersion()}/hse-handbook/print-new-version`,
      {
        safety: this.safetyFieldValue,
        representativeUserUuid: this.userSelectValue,
      },
      { responseType: 'blob' }
    )
      .then(resp => {
        const url = window.URL.createObjectURL(resp.data);
        window.open(url, '_blank');
      });
  }

  printWithFilters() {
    if (!this.versionSelectValue) {
      return this.$store.dispatch(GlobalActions.showSnackBar, {
        type: SnackBarTypes.error,
        message: __('company.views.hse-handbook.index.actions.version-required')
      })
    }

    this.http.post(
      `${getNodeApiUrlByVersion()}/hse-handbook/print-with-signatures`,
      {
        hseHandbookUuid: this.versionSelectValue,
        ...this.handbookFilters
      },
      { responseType: 'blob' }
    )
      .then(resp => {
        const url = window.URL.createObjectURL(resp.data);
        window.open(url, '_blank');
      });
  }

  sendForSigning() {
    const selected = this.table.selected;

    if (!selected.length) {
      return this.$store.dispatch(GlobalActions.showDialog, {
        show: true,
        component: InfoDialog,
        meta: {
          title: __('company.views.hse-handbook.index.actions.select-users.title'),
          text: __('company.views.hse-handbook.index.actions.select-users.text'),
        },
      })
    }

    if (
      Object.values(this.handbookFilters).some(val => val === false) ||
      this.handbookFilters.dateTo || this.handbookFilters.dateFrom
    ) {
      return this.$store.dispatch(GlobalActions.showDialog, {
        show: true,
        component: InfoDialog,
        meta: {
          title: __('company.views.hse-handbook.index.actions.remove-filters.title'),
          text: __('company.views.hse-handbook.index.actions.remove-filters.text'),
        },
      })
    }

    const send = () => {
      this.http.post(`${getNodeApiUrlByVersion()}/hse-handbook-signatures/send`, {
        userUuids: selected.map(user => user.uuid),
        handbookUuid: this.versionSelectValue
      })
        .then((data) => {
          this.$store.dispatch(GlobalActions.showSnackBar, {
            type: SnackBarTypes.success,
            message: `${data?.data?.count} ${__('company.views.hse-handbook.index.actions.emails-sent')}`
          })
          this.tableRef.refresh();
        });
    }

    if (this.versionSelectValue === this.lastHandbook?.uuid) {
      return this.$store.dispatch(GlobalActions.showDialog, {
        show: true,
        component: AreYouSureDialog,
        meta: {
          title: __('company.views.hse-handbook.index.actions.send-for-signing.title'),
          text: __('company.views.hse-handbook.index.actions.send-for-signing.text'),
          yes: __('general.send'),
          no: __('general.cancel'),
          onYes: send,
        },
      })
    }

    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('company.views.hse-handbook.index.actions.send-old-for-signing.title'),
        text: __('company.views.hse-handbook.index.actions.send-old-for-signing.text'),
        yes: __('general.send'),
        no: __('general.cancel'),
        onYes: send,
      },
    })
  }

  remindToSign() {
    const selected = this.table.selected;

    if (!selected.length) {
      return this.$store.dispatch(GlobalActions.showDialog, {
        show: true,
        component: InfoDialog,
        meta: {
          title: __('company.views.hse-handbook.index.actions.select-users.title'),
          text: __('company.views.hse-handbook.index.actions.select-users.text'),
        },
      })
    }

    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('company.views.hse-handbook.index.actions.remind.title'),
        text: __('company.views.hse-handbook.index.actions.remind.text'),
        yes: __('general.remind'),
        no: __('general.cancel'),
        onYes: () => {
          this.http.post(`${getNodeApiUrlByVersion()}/hse-handbook-signatures/remind`, {
            userUuids: selected.map(user => user.uuid)
          })
          .then((data) => {
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: `${data?.data?.count} ${__('company.views.hse-handbook.index.actions.emails-sent')}`
            })
          });
        },
      },
    })
  }

  get tableRef(): any {
    return this.$refs.table
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  get shouldNewVersionApprovalEnabled() {
    return this.isVisibleNewVersionApproval ||
      this.lastHandbook?.safetyType !== this.safetyFieldValue ||
      this.lastHandbook?.safetyRepresentativeUuid !== this.userSelectValue
  }
}
