









import { Component, Vue } from 'vue-property-decorator'
import FormField from '@/shared/components/form/FormField.vue'
import can from '@/shared/helpers/can.helper'
import __ from '@/shared/helpers/__'
import { getNodeApiUrlByVersion } from "@/config";
import _ from "lodash";
import { getQueryName } from "@/shared/helpers/query.helper";
import { FilterOperators } from "@/shared/classes/components/data-table/data-table-filter";
import SelectField from "@/shared/classes/form/fields/Select/select-field";
import SelectItem from "@/shared/classes/form/fields/Select/select-item";
import User from "@/shared/modules/user/user.model";
import baseHttp from "@/shared/http";
import IResponse from "@/shared/interfaces/modules/response.interface";

@Component({
  components: { FormField },
  methods: { can }
})
export default class UsersFilters extends Vue {
  http = baseHttp
  statusSelect: SelectField | null = null
  roleSelect: SelectField | null = null
  positionSelect: SelectField | null = null
  signingStatusSelect: SelectField | null = null

  setQueryParam(key: string, value: string) {
    let { query, name }: any = this.$router.currentRoute
    query = { ...query }
    if (value) _.set(query, getQueryName(FilterOperators.equals, key), value)
    else delete query[getQueryName(FilterOperators.equals, key)];

    this.$router.push({ name, query }).catch(() => {})
  }

  created() {
    this.statusSelect = new SelectField()
      .isMultiple()
      .setKey('statusUuid')
      .setTitle(__('company.components.hse-handbook.user-filters.form.status'))
      .setDense(true)
      .loadItems({ endpoint: `${getNodeApiUrlByVersion()}/statuses/user`, value: 'uuid', title: 'name' })
      .setOnChange((value) => this.setQueryParam('statusUuid', value))

    this.http.get('/roles?per_page=500')
      .then((response: IResponse<any>) => {
        this.roleSelect = new SelectField()
          .isMultiple()
          .setKey('roleUuid')
          .setTitle(__('company.components.hse-handbook.user-filters.form.role'))
          .setDense(true)
          .setOnChange((value) => this.setQueryParam('roleUuid', value))
          .setItems(response.data.data
            .filter((el: any) => el.level !== 5)
            .map((item: any) => {
              return new SelectItem()
                .setTitle(item.name)
                .setValue(item.uuid);
            }))
      })

    this.positionSelect = new SelectField()
      .isMultiple()
      .setKey('positionUuid')
      .setTitle(__('company.components.hse-handbook.user-filters.form.position'))
      .setDense(true)
      .loadItems({ endpoint: '/positions', value: 'uuid', title: 'name' })
      .setOnChange((value) => this.setQueryParam('positionUuid', value))

    this.signingStatusSelect = new SelectField()
      .isMultiple()
      .setKey('signingStatus')
      .setTitle(__('company.components.hse-handbook.user-filters.form.signing-status'))
      .setDense(true)
      .setItems([
        new SelectItem()
          .setValue('all')
          .setTitle(__('company.components.hse-handbook.signing-status.all')),
        new SelectItem()
          .setValue('signed-last-version')
          .setTitle(__('company.components.hse-handbook.signing-status.signed-last-version')),
        new SelectItem()
          .setValue('signed-old-version')
          .setTitle(__('company.components.hse-handbook.signing-status.signed-old-version')),
        new SelectItem()
          .setValue('not-sent')
          .setTitle(__('company.components.hse-handbook.signing-status.not-sent')),
        new SelectItem()
          .setValue('sent-version-not-signed')
          .setTitle(__('company.components.hse-handbook.signing-status.sent-version-not-signed')),
      ])
      .setOnChange((value) => this.setQueryParam('signingStatus', value))
  }

  mounted() {
    this.$watch('$route', this.watchRoute)
    let { query, name }: any = this.$router.currentRoute;
    this.$router.replace(({
      name,
      query: {
        [getQueryName(FilterOperators.equals, 'statusUuid')]: User.STATUS_ACTIVE,
        [getQueryName(FilterOperators.equals, 'signingStatus')]: 'all',
        ...query,
      }
    })).catch(() => {});
  }

  private watchRoute(): void {
    const query = this.$router.currentRoute.query;

    const getValueAndFormat = (key: any) => {
      const value = query?.[getQueryName(FilterOperators.equals, key)]

      if (!value) return value;
      if (Array.isArray(value)) return value;
      else return [value]
    }

    this.statusSelect?.setValue(getValueAndFormat('statusUuid'));
    this.roleSelect?.setValue(getValueAndFormat('roleUuid'))
    this.positionSelect?.setValue(getValueAndFormat('positionUuid'))
    this.signingStatusSelect?.setValue(getValueAndFormat('signingStatus'))
  }
}
