
























import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import __ from '@/shared/helpers/__'
import HseHandbook from "@/shared/modules/hse-handbook/hse-handbook.model";

@Component({})
export default class HseHandbookSigningStatusHeader extends Vue {
  @Prop() item!: any
  @Inject('lastHandbook') getLastHandbook!: () => HseHandbook

  get notSent() {
    if (this.item.hseHandbookSignatures === 0) return __('company.components.hse-handbook.signing-status.not-sent');
  }

  get notSigned() {
    if (this.item.lastSentInfo) return __('company.components.hse-handbook.signing-status.sent-version-not-signed');
  }

  get signedPrev() {
    if (this.item.lastSignedInfo && this.item.lastSignedInfo.handbookUuid !== this.getLastHandbook()?.uuid) {
      return __('company.components.hse-handbook.signing-status.signed-old-version');
    }
  }

  get signedLast() {
    if (this.item.lastSignedInfo && this.item.lastSignedInfo.handbookUuid === this.getLastHandbook()?.uuid) {
      return __('company.components.hse-handbook.signing-status.signed-last-version');
    }
  }
}
